<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/bonuses/watch/${this.$route.params.id}`"
        />

        <div class="pageSection">
            <div class="pageContent backgroundZircon bonusWebinarsVideoWrapper">
                <VideoArticle
                    :title="videoArticle.title"
                    :date="videoArticle.date"
                    :videoThumb="videoArticle.videoThumb"
                    :text="videoArticle.text"
                    :author="videoArticle.author"
                />
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

import Button from "@/views/components/Button/Button";
import TextTimer from "@/views/components/TextTimer/TextTimer";
import TextArea from "@/views/components/TextArea/TextArea";
import VideoArticle from "@/views/components/VideoArticle/VideoArticle";

import VideoThumb from "../../../../assets/img/pages/bonuses/webinars/video/video.png";
import axios from "axios";

export default {
    name: "bonus",

    components: {
        Layout,
        PageHeader,
        Button,
        TextTimer,
        TextArea,
        VideoArticle,
        VideoThumb,
    },

    async beforeMount() {
        const $this = this;
        await $this.updateBonusData()
            .then(async (updateBonusDataResult) => {
                if (updateBonusDataResult) {
                    $this.renderPageData();
                    await $this.setWatchStatus("inprogress");
                    await $this.watchTimer(30000);
                } else {
                    await this.$store.dispatch("clearBonus");
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Ошибка при загрузке бонуса",
                        },
                    });
                }
        });
    },

    data() {
        return {
            isVisibleAnswer: false,
            isVisibleModal: false,

            title: "",
            items: [
                {
                    text: "Бонусы",
                    to: `/${this.$route.params.slug}/bonuses`,
                },
                {
                    text: "",
                    to: `/${this.$route.params.slug}/bonuses/watch/${this.$route.params.id}`,
                    active: false,
                },
                {
                    text: "",
                    active: true,
                },
            ],

            bonus: {},

            video: {},

            videoArticle: {},
        };
    },

    methods: {
        onSendAnswer() {
            this.isVisibleAnswer = true;
        },

        onOpenModal() {
            this.isVisibleModal = true;
        },

        onCloseModal() {
            this.isVisibleModal = false;
        },

        async wait (ms) {
            return new Promise(resolve => {
                setTimeout(resolve, ms);
            });
        },

        async watchTimer (ms) {
            await this.wait(ms);
            await this.setWatchStatus("done");
        },

        async setWatchStatus (status) {
            if (this.bonus.materials && this.bonus.materials.length > 0) {
                for (const [index, material] of this.bonus.materials.entries()) {
                    if (index === parseInt(this.$route.params.video_id, 10)) {
                        if (!material.video_status && material.video_status !== "done") {
                            await axios.post("/programs/" + this.$route.params.slug + "/bonuses/videostatus", { status: status, _id: material._id });
                        }
                    }
                }
            }
        },

        renderPageData: function () {
            this.title = this.$store.getters.getBonus.category.title;
            this.items[1].text = this.$store.getters.getBonus.category.title;
            this.items[2].text = this.$store.getters.getBonus.category.materials[parseInt(this.$route.params.video_id, 10)].name;
            this.bonus = this.$store.getters.getBonus.category;

            if (this.bonus.materials && this.bonus.materials.length > 0) {
                for (const [index, material] of this.bonus.materials.entries()) {
                   if (index === parseInt(this.$route.params.video_id, 10)) {
                       this.video = material;
                       this.videoArticle = {
                           title: material.name,
                           date: material.video_date,
                           videoThumb: material.url,
                           text: material.description,
                           author: material.video_lector
                       };
                   }
                }
            }
        },

        updateBonusData: async function () {
            const $this = this;
            const getBonusDataResult = await axios.get("/programs/" + this.$route.params.slug + "/bonuses/" + this.$route.params.id);
            if (getBonusDataResult && getBonusDataResult.data && getBonusDataResult.data.result) {
                return await $this.$store.dispatch("setBonus", getBonusDataResult.data)
                    .then(() => {
                        return true;
                    });
            } else {
                return true;
            }
        },
    },
};
</script>

<style lang="scss">
@import "./bonus.scss";
</style>
