<template>
    <div :class="customIframe ? 'videoArticleWithoutWidth' : 'videoArticle'">
        <div v-if="title && date" class="videoArticle__header">
            <p class="h3 videoArticle__title">
                {{ title }}
            </p>
            <p class="boxText boxText--grey2 videoArticle__date">
                {{ date }}
            </p>
        </div>
        <div class="videoArticle__content">
            <div
                v-if="customIframe"
                v-html="customIframe"
                class="videoArticle__customIframe"
            ></div>
            <iframe
                v-else
                class="videoArticle__content_videoContent"
                width="100%"
                height="376"
                :src="videoThumb"
                title="Vimeo video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
            <ButtonVideoProblem title="трудности с воспроизведением ?" />
        </div>
        <p v-if="text" class="videoArticle__text">
            {{ text }}
        </p>
        <p v-if="author" class="videoArticle__text">Лектор: {{ author }}.</p>
    </div>
</template>

<script>
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";

export default {
    name: "VideoArticle",

    components: {
        ButtonVideoProblem,
    },

    props: {
        title: String,
        date: String,
        videoThumb: String,
        text: String,
        author: String,
        customIframe: String,
    },
};
</script>

<style lang="scss" scoped>
@import "VideoArticle.scss";
</style>
