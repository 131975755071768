<template>
    <div :class="(extraClass || '') + ' textTimer title1 title1--2'">
        {{ this.countDown }}
    </div>
</template>

<script>
export default {
    name: "TextTimer",

    props: {
        extraClass: String,
        time: String
    },

    data() {
        return {
            countDown: ""
        }
    },

    mounted: function () {
        this.launchTimer();
    },

    methods: {
        launchTimer() {
            const countDownDate = new Date(this.time).getTime();

            const timerInterval = setInterval(function () {
                const now = new Date().getTime();

                const distance = countDownDate - now;

                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                this.countDown =
                    days + this.dateText(days, "day") + " " +
                    hours + this.dateText(hours, "hour") + " : " +
                    minutes + this.dateText(minutes, "minutes") + " : " +
                    seconds + this.dateText(seconds, "seconds");

                if (distance < 0) {
                    clearInterval(timerInterval);
                    this.countDown =
                        "0" + " дней" + " " +
                        "0" + " часов" + " : " +
                        "0" + " минут" + " : " +
                        "0" + " секунд";
                }
            }.bind(this), 1000);
        },
        dateText(number, type) {
            const format_number = parseInt(number, 10) % 100;
            const modulo = format_number % 10;
            if (type === 'day') {
                if ((modulo >= 5) || (modulo === 0) || ((format_number > 10) && (format_number < 20))) {
                    return ' дней';
                } else if (modulo === 1) {
                    return ' день';
                } else if ((modulo > 1) && (modulo < 5)) {
                    return ' дня';
                }
            } else if (type === 'hour') {
                if ((modulo >= 5) || (modulo === 0) || ((format_number > 10) && (format_number < 20))) {
                    return ' часов';
                } else if (modulo === 1) {
                    return ' час';
                } else if ((modulo > 1) && (modulo < 5)) {
                    return ' часа';
                }
            } else if (type === 'minutes') {
                if ((modulo >= 5) || (modulo === 0) || ((format_number > 10) && (format_number < 20))) {
                    return ' минут';
                } else if (modulo === 1) {
                    return ' минуту';
                } else if ((modulo > 1) && (modulo < 5)) {
                    return ' минуты';
                }
            } else if (type === 'seconds') {
                if ((modulo >= 5) || (modulo === 0) || ((format_number > 10) && (format_number < 20))) {
                    return ' секунд';
                } else if (modulo === 1) {
                    return ' секунду';
                } else if ((modulo > 1) && (modulo < 5)) {
                    return ' секунды';
                }
            } else {
                return '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "textTimer.scss";
</style>
